/* ccsl - bonus START */

if (typeof CCSL === "undefined") {
  var CCSL = {};
}

CCSL.openOverlay = (function () {
  var openYT, _init;

  openYT = function () {
    $(".overlay-youtube").magnificPopup({
      disableOn: 700,

      type: "iframe",

      mainClass: "mfp-fade",

      removalDelay: 160,

      preloader: false,

      fixedContentPos: false,
    });
  };

  _init = function () {
    openYT();
  };

  return {
    init: _init,
  };
})();

$(document).ready(function () {
  CCSL.openOverlay.init();
});

/* ccsl - bonus END */

// $(document).on("ready", function () {
//   // collapsable items (faq/glossary)
//   $("a.collapsable-toggler").on("click", function () {
//     $(this)
//       .closest(".collapsable")
//       .toggleClass("open")
//       .find(".collapsable-content")
//       .slideToggle(100);
//   });
// });

$(window).resize(function () {
  RESIZE.init();
});

var RESIZE = {
  init: function () {
    if ($(window).width() > 768) {
      $owlContainer = $(".owl-wrapper-outer");
      $owlContainer.find(".owl-item").css({
        width: $owlContainer.outerWidth() / 2,
      });
    } else {
      $owlContainer = $(".owl-wrapper-outer");
      $owlContainer.find(".owl-item").css({
        width: $owlContainer.outerWidth(),
      });
    }

    this.setSidebarAndMainHeight();
  },

  setSidebarAndMainHeight: function () {
    $sidebar = $(".sidebar");
    $main = $(".main");
    max =
      $sidebar.css("height", "auto").outerHeight() >
      $main.css("height", "auto").outerHeight()
        ? $sidebar.outerHeight()
        : $main.outerHeight();
    $($sidebar, $main).css("height", max);
  },
};

(function ($) {
  $.fn.setHeight = function (options) {
    if (this.length == 0) return this;

    var el = this;
    var main = $(this).parent().find(".main");

    //reset element height
    $(el).css({ height: "auto" });

    var sb_h = $(el).height();
    var main_h = $(main).outerHeight();

    if (sb_h < main_h) $(el).height(main_h);

    // returns the current jQuery object
    return this;
  };
})(jQuery);
